<script lang="ts" setup>
const pageData = [
	{
		id: "safe",
		icon: "benefits-safe",
		title: "free coins every day",
		text: "Unlock daily free coins by opening the Magic Box, exploring Special Offers, and completing Quests for guaranteed rewards and great bargains.",
		btn: "join now",
		size: {
			width: 76,
			height: 115
		}
	},
	{
		id: "chat",
		icon: "benefits-chat",
		title: "24/7 Customer Service",
		text: null,
		btn: "Ask a question",
		size: {
			width: 341,
			height: 117
		}
	},
	{
		id: "top",
		icon: "benefits-top",
		title: "No purchase necessary",
		text: null,
		btn: null,
		size: {
			width: 341,
			height: 130
		}
	}
];

const { open } = useTaoModals();

const { handleSupportButtonClick } = useSupportChat();

const { isOneClickRegister } = useOtpGetFlow();

const clickHandler = (benefit: string) => {
	if (benefit === "safe") {
		if (isOneClickRegister) {
			open("LazyOModalOneClickSignup");
			return;
		}
		open("LazyOModalSignup", { location: "benefits" });
	}
	if (benefit === "chat") {
		handleSupportButtonClick();
	}
	if (benefit === "top") {
		navigateTo("/how-it-works");
	}
};
</script>

<template>
	<div class="benefits">
		<div v-for="item in pageData" :key="item.id" class="benefits__item" :class="item.icon">
			<NuxtImg
				class="benefits__icon"
				:src="`/nuxt-img/benefits/${item.icon}.png`"
				:width="item.size?.width"
				:height="item.size?.height"
				:alt="item.title"
				format="avif"
				loading="lazy"
			/>
			<div class="benefits__title text-h6" v-html="item.title" />
			<div v-if="item.text" class="benefits__text text-base" v-html="item.text" />
			<AButton
				v-if="item.btn"
				variant="primary"
				size="s"
				class="benefits__btn"
				:modifiers="item.id !== 'safe' ? ['outlined'] : []"
				@click="clickHandler(item.id)"
				>{{ item.btn }}</AButton
			>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.benefits {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 276px;
	gap: 16px;

	@include media-breakpoint-down(md) {
		height: auto;
	}

	&__icon {
		max-height: 100%;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 2;
	}

	&__item {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;
		border-radius: 12px;
		position: relative;
		overflow: hidden;
		padding: 24px 64px 24px 24px;
		color: var(--neutral);

		@include media-breakpoint-down(md) {
			height: auto !important;
			width: 100% !important;
		}

		.benefits__btn {
			width: 100%;
			max-width: 240px;
			margin-top: 16px;
			position: relative;
			z-index: 2;
		}

		&.benefits-safe {
			width: 330px;
			min-height: 100%;
			background: var(--gradient-2);

			.benefits__title {
				@include media-breakpoint-down(md) {
					padding-right: 0;
				}
			}

			.benefits__icon {
				user-select: none;
				top: 24px;
				right: 0;
			}
		}

		&.benefits-chat {
			width: calc(100% - 346px);
			height: 130px;
			background: var(--gradient-4);

			.benefits__icon {
				bottom: 0;
				right: 0;

				@include media-breakpoint-down(md) {
					height: 75px;
					width: auto;
					bottom: auto;
					top: 10px;
				}
			}
		}

		&.benefits-top {
			width: calc(100% - 346px);
			height: 130px;
			background: var(--gradient-1);
			justify-content: center;

			.benefits__icon {
				right: 0;
				top: 0;

				@include media-breakpoint-down(md) {
					height: 85px;
					width: auto;
				}
			}
		}
	}

	&__title {
		user-select: none;
		font-style: normal;
		position: relative;
		z-index: 5;

		@include media-breakpoint-down(md) {
			max-width: 240px;
		}
	}

	&__text {
		margin-top: 8px;
		position: relative;
		z-index: 5;
	}
}
</style>
